
    .wrap-icon {
        border-radius: 0.45rem;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
        padding: 0.5rem;
        height: 2.5rem;
        background: #fff;
    .comman-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
