.ncash_bg-lightpurple {
  background-color: #fbebff;
  padding: 5px;
  border-radius: 10px;
}
.ncash_bg-lightblue {
  background-color: #f3fbff;

  padding: 5px;
  border-radius: 10px;
}
.ncash_bg-lightpink {
  background-color: #fff1f1;


  padding: 5px;
  border-radius: 10px;
}
