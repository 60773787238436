.card-counts {
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  display: grid;
  grid-gap: 15px;
  .card-count {
    cursor: pointer;
    width: 100%;
    opacity: 0.96;
    &:hover {
      opacity: 1;
    }
    .white-card {
      width: 100% !important;
      height: 100%;
      margin-bottom: 0;
    }
  }
}
