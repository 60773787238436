.page-heading {
  .title {
    color: rgb(134, 134, 134);
  }
  a {
    text-decoration: none;
  }
  a:hover {
    color: #000;
  }
}

.color0 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #fff;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color1 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #c6f1ff;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color2 {
  // color: #fffee9 !important;
  &.white-card {
    background: #fffee9;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color3 {
  // color: #88d0eb !important;
  &.white-card {
    background: #88d0eb;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color4 {
  // color: #dcf6ff !important;
  &.white-card {
    background: #dcf6ff;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color5 {
  // color: #ebcfee !important;
  &.white-card {
    background: #ebcfee;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color6 {
  // color: #ffeddb !important;
  &.white-card {
    background: #ffeddb;
    &.status-dot-card {
      background: #fff;
    }
  }
  &.active {
    border: 1px solid #000;
  }
}
.color7 {
  // color: #dcffe8 !important;
  &.white-card {
    background: #dcffe8;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color8 {
  // color: #f98464 !important;
  &.white-card {
    background: #f98464;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color9 {
  // color: #ececec !important;
  &.white-card {
    background: #ececec;
  }
  &.active {
    border: 1px solid #000;
  }
}
.status-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  right: 10px;
}
.status-dot-card {
  &.color0 .status-dot {
    background: #fff;
    color: #000;
  }
  &.color1 .status-dot {
    background: #38a3a5;
  }
  &.color2 .status-dot {
    background: #5C98C0;
  }
  &.color3 .status-dot {
    background: #5C98C0;
  }
  &.color4 .status-dot {
    background: #A1E1CF;
  }
  &.color5 .status-dot {
    background: #8EE3A7;
  }
  &.color6 .status-dot {
    background: #84CAE7;
  }
  &.color7 .status-dot {
    background: #00A676;
  }
  &.color8 .status-dot {
    background: #e5989b;
  }
  &.color9 .status-dot {
    background: #5FCF97; 
  }
  &.color10 .status-dot {
    background: #694ff8;
  }
  &.color11 .status-dot {
    background: #ff6d6d;
  }
  &.color12 .status-dot {
    background: #8EE3A7;
  }
  &.color13 .status-dot {
    background: #dc2f02;
  }
  &.color14 .status-dot {
    background: #A1E1CF;
  }
  &.color15 .status-dot {
    background:  #9a8c98;
  }
  &.color16 .status-dot {
    background: #bb3e03;
  }
}

.white-card {
  &.status-dot-card {
    position: relative;
    background: #fff;
    &.bg-gray {
      background-color: #ececec !important;
    }
  }
}
