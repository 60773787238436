.white-card {
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1rem;
  background: #fff;
  &.active {
    background: #ececec;
  }
}
