.primary-gradient {
  background-image: linear-gradient(180deg, #163e5e, #0a1724);
  width: 100%;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //border-radius: 1.3rem 0 0 1.3rem;
  position: sticky;
  top: 0;
  bottom: 0;
}

.dashboard-body-bg {
  background: #fbfaff;
}

.dashboard-sidebar-container,
.dashboard-content-container {
  position: relative;
  padding-left: 0;
}
.dashboard-sidebar {
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100px;
}
@media (min-width: 786px) {
  .menu-max-width {
    max-width: 110px;
  }
}

@media (min-width: 992px) {
  .menu-max-width {
    width: 120px;
  }
}
@media (min-width: 1024px) {
  .menu-max-width {
    width: 110px;
  }
}
@media (min-width: 1400px) {
  .menu-max-width {
    max-width: 100px;
  }
}
