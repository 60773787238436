#custom-search {
  width: 30rem;
  height: 3rem;
  display: flex;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  button {
    border: none;
    background: #fff;
    padding: 0 1rem;
    border-radius: 0.5rem;
  }
  input[type="search"] {
    -webkit-appearance: textfield;
    width: 100%;
    padding: 0.65rem;
    border: none;
    border-radius: 0.5rem;
    &:focus {
      outline: none;
      outline-offset: none;
    }
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    // height: 1em;
    // width: 1em;
    border-radius: 50em;
    //background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 0.3;
    pointer-events: all;
  }

  input[type="search"].dark::-webkit-search-cancel-button {
    filter: invert(1);
  }
}
