.wrapper-md {
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;

	& > img {
		width: 3rem;
		height: 3rem;
	}
}
