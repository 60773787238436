.roles {
  cursor: pointer;
}
.maincon {
  width: "100%";
  padding-left: 2%;
  padding-right: 2%;
}
.namebar {
  display: flex;
  // justify-content: space-evenly;
  flex-direction: row;
  // align-items: center;
  padding-bottom: 1%;
}
.modal {
  width: "100%";
}
.subcon1 {
  display: flex;
  flex-direction: row;
}
.subcon2 {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
}
.subcon3 {
  display: flex;
  flex-direction: row;
  padding-top: 2%;
}
.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 220px;
}
.css-b62m3t-container1 {
  position: relative;
  box-sizing: border-box;
  width: 260px;
}
.select {
  position: relative;
  box-sizing: border-box;
  width: 130px;
  padding-right: 3%;
}
.textfield {
  width: 150px;
}
.textfield1 {
  width: 50px;
}

.tr2:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.box {
  border: 2px solid black;
  padding: 10px;
  width: 280px;
  height: 400px;
  background-color: white;
  border-radius: 1%;
}
.box1 {
  // border: 2px solid black;
  padding: 10px;
  width: 275px;
  height: 330px;
  background-color: white;
  // border-radius: 1%;
}
.b-color {
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}
div::-webkit-scrollbar {
  display: none;
}
.bottombar {
  border-bottom: 3px solid #113459;
}
.namebar1 {
  display: flex;
  // justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
}
.widthinput {
  width: 260px;
  background-color: white;
}
.search-bg {
  background-color: white;
  width: 100%;
  position: relative;
}
