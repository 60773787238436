ul li {
  padding: 2px 15px 0px 0px;
}
ul li a {
  text-decoration: none;
}
.nav-item .active {
  border-bottom: 4px solid #4b5157;
  color: #113459;
}
.nav-link {
  padding: 0.2rem 1rem;
  cursor: pointer;
}
