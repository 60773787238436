.ncash_bg-lightpurple {
  background-color: #fff1f1;
  padding: 5px;
  border-radius: 10px;
}
.ncash_bg-lightblue {
  background-color: #ddf3ff;

  padding: 5px;
  border-radius: 10px;
}
.ncash_bg-lightpink {
  background-color: #fdefff;

  padding: 5px;
  border-radius: 10px;
}
