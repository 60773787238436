.menu-list-container {
  width: 117px;
}
ul.menu-list::-webkit-scrollbar {
  display: none;
}
ul.menu-list {
  list-style: none;
  margin: 0;
  margin-left: 0px;
  padding: 0;
  max-height: calc(100vh - 9rem);
  margin-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  li {
    display: flex;
    flex-direction: column;
    width: 77px;
    a {
      font-size: 0.75rem;
      color: #c6f1ff;
      text-decoration: none;
      line-height: 0.5rem;
      padding: 1rem 0.5rem;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
      }
      &:hover,
      &.active {
        background: #4b6f88;
        border-radius: 0.4rem;
        color: #fff;
      }
      p {
        margin: 0;
      }
    }
  }
}
